.sub-nav {
    overflow: hidden;
    position: absolute;
    top: calc(100% + .5em);
    left: 0;
    max-height: 0;
    white-space: nowrap;
    background-color: white;
    transition: max-height .6s linear;
    @media (--ipad){
        position: static;
        margin-top: .7em;
        max-height: none;
        background-color: transparent;
    }
    @media (--ipad_mini){
        position: static;
        margin-top: .7em;
        max-height: none;
        background-color: transparent;
    }
    @media (--phone){
        position: static;
        margin-top: .7em;
        max-height: none;
        background-color: transparent;
    }
}
