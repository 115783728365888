.sub-nav__item {
    > a {
        display: block;
        padding: .4em;
        background-color: white;
        font: 14px regular, serif;
        @media (--ipad){
            padding-left: 1em;
        }
    }

    &_current > a,
    > a:hover {
        background-color: var(--color__primary);
        color: white;
    }
}
