.page__text {
    position: relative;
    z-index: var(--zIndex__bottom);
    width: 16em;
    margin: 0 auto;
    padding: 1em;
    background-color: var(--color__primary);
    transform:  translateY(-64%);
    text-align: center;
    font: 1rem/1.6 regular, serif;
    color: white;
    @media (--ipad_mini) {
        margin: 1em auto;
        transform:  translateY(0);
    }
    @media (--phone) {
        margin: 1em auto;
        transform:  translateY(0);
    }

    > b {
        display: block;
        font-weight: normal;
        font-family: bold, serif;
    }

    &:after {
        content: '';
        display: block;
        width: 30%;
        height: 1px;
        margin: 2em auto .8em;
        background-color: white;
    }
}
