.address__item {
    position: relative;
    margin-top: 1em;
    padding-left: 46px;
    @media (--phone){
        padding-left: 0;
        padding-top: 2em;
        text-align: center;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 10px;
        width: 18px;
        height: 20px;
        background: url('../images/icons_address.png') left top/100% auto no-repeat;
        @media (--phone){
            left: 50%;
            transform:  translateX(-50%);
        }
    }

    &_location:before {
        background-position: left top;
    }

    &_tel:before {
        background-position: left -26px;
    }

    &_email:before {
        background-position: left -50px;
    }
}
