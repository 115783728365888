.menu-nav__item {
    position: relative;

    & + & {
        margin-top: 10px;
    }

    > a {
        text-transform: uppercase;
        font: 1rem regular, serif;
        color: white;
    }

    &.nav__item_current > a {
        font-family: bold, serif;
        color: var(--color__red);
    }
}
