.feature__item {
    width: calc(100% / 5);
    text-align: center;
    @media (--ipad_mini) {
        width: calc(100% / 3);
        margin-top: 2em;
    }
    @media (--phone) {
        width: calc(100% / 1);
        margin-top: 2em;
    }
}
