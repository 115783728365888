.service-item {
    display: block;
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: color(black a(.4));
    }

    &:hover {
        &:after {
            background-color: transparent;
            transition: background-color .4s;
        }

        .service-item__img {
            transform:  scale(1.1);
        }

        .service-item__title {
            opacity: 0;
            transition: opacity .4s;
        }
    }
}
