.contact-info__item{
    margin-left: 14px;
    line-height: 1;

    > a {
        font: 14px regular, serif;
        color: white;

        &:hover {
            text-decoration: underline;
        }
    }

    &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 6px;
        background: url('../images/icons.png') no-repeat;
        background-size: auto 100%;
        vertical-align: middle;
    }

    &_tel {
        > a {
            color: var(--color__primary);
        }

        &:before {
            background-position: left top;
        }
    }

    &_email:before {
       background-position: right top;
    }
}
