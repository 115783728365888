.nav__item {
    display: inline-block;
    position: relative;

    & + & {
        margin-left: 2em;
    }

    > a {
        text-transform: uppercase;
        font: .8rem regular, serif;
        color: white;

    }

    &:hover {
        > a {
            color: var(--color__red);
        }
        > .sub-nav {
            max-height: 10em;
        }
    }
}
