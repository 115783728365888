.nav {
    @media (--ipad){
        display: none;
    }
    @media (--ipad_mini){
        display: none;
    }
    @media (--phone){
        display: none;
    }
}
