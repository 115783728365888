.item__wrap {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.5em;
    background-color: color(black a(.5));
    flex-flow: column wrap;
    justify-content: flex-end;
}
