.gallery__btn {
    width: 30px;
    height: 30px;
    border: 1px solid white;
    border-radius: 50%;
    background-size: 30% auto;

    &_next {
        right: 30px;
    }

    &_prev {
        left: 30px;
    }
}
