.passenger-item {
    display: block;
    position: relative;

    &:hover {
        .passenger-item {
            &__img {
                filter: grayscale(100%); 

                &:after {
                    opacity: 1;
                    transform:  translateY(0);
                }
            }

            &__info {
                background-color: var(--color__primary);
            }

            &__caption {
                color: white;
            }

            &__title {
                color: var(--color__primary_darken);
            }

            &__text {
                color: white;
            }
        }
    }
}
