.feature__icon {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

@for $i from 1 to 5 {
    .feature__icon_$(i) {
        background-image: svg-inline(feature_$(i));
    }
}
