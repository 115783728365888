.enquiry__item {
    width: 100%;
    padding: .3em 1em;
    margin-top: 1em;
    border: 1px solid var(--color__primary);
    border-radius: .4em;
    background-color: transparent;
    font: .7rem/2.2 regular, serif;
    color: var(--color__primary);

    &:focus {
        outline: 0;
    }

    &::placeholder {
        opacity: 1;
        color: var(--color__primary);
    }

    &_half {
        width: 49%;
        @media (--phone){
            width: 100%;
        }
    }

    &[name="message"]{
        resize: none;
    }
}
