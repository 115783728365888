.clearp_l {
    padding-left: 0;
    @media (--phone) {
        padding-left: 15px;
    }
}
.clearp_r {
    padding-right: 0;
    @media (--phone) {
        padding-right: 15px;
    }
}
