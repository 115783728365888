/* ------ venobox.css --------*/
.vbox-overlay *, 
.vbox-overlay *:before, 
.vbox-overlay *:after{
    -webkit-backface-visibility: hidden;
    -webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
body{
    -webkit-overflow-scrolling:touch;
}
/* ------- overlay: change here background color and opacity ----- */
.vbox-overlay{
    background: rgba(0,0,0,0.85); 
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: hidden;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}
.relativo{
    float: left;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}
/* ----- preloader ----- */
.vbox-preloader{
    position:fixed; 
    width:36px; 
    height:36px;
    left:50%; 
    top:50%; 
    margin-left:-18px; 
    margin-top:-18px;
    background: url(../venobox/preload.png) center center no-repeat;
    text-indent: -100px;
    overflow: hidden;
}

/* ----- navigation ----- */
.vbox-close{
    cursor: pointer;
    position: fixed;
    top: 10%;
    right: 50%;
    transform: translateX(400px);
    width: 31px;
    height: 31px;
    /* padding: 10px 20px 10px 0; */
    display: block;
    background: url(../venobox/close.png) no-repeat #161617;
    /* background-position:10px center; */
    color: #fff;
    text-indent: -100px;
    overflow: hidden;
}
@media screen and (max-width: 768px) {
    .vbox-close {
        right: 10px;
        transform: translateX(0);
    }
}
.vbox-next, .vbox-prev{
    cursor: pointer;
    position: fixed;
    top: 50%;
    color: #fff;
    width: 49px;
    height: 43px;
    margin-top: -15px;
    text-indent: -100px;
    overflow: hidden;
}
.vbox-prev{
    left: 50%;
    transform: translateX(-400px);
    background: url(../venobox/prev.png) center center no-repeat;
}
.vbox-next{
    right: 50%;
    transform: translateX(400px);
    background: url(../venobox/next.png) center center no-repeat;
}
@media screen and (max-width: 768px) {
    .vbox-next {
        right: 10px;
        transform: translateX(0);
    }
    .vbox-prev {
        left: 10px;
        transform: translateX(0);
    }
}

.vbox-title{
    width: 100%;
    height: 40px;
    float: left;
    text-align: center;
    line-height: 28px;
    font-size: 12px;
    color: #fff;
    padding: 6px 40px;
    overflow: hidden;
    background: #161617;
    position: fixed;
    display: none;
    top: -1px;
    left: 0;

}
.vbox-num{
    cursor: pointer;
    position: fixed;
    top: -1px;
    left: 0;
    height: 40px;
    display: block;
    color: #fff;
    overflow: hidden;
    line-height: 28px;
    font-size: 12px;
    padding: 6px 10px;
    background: #161617;
    display: none;
}
/* ------- inline window ------ */
.vbox-inline{
    width: 420px;
    height: 315px;
    padding: 10px;
    background: #fff;
    text-align: left;
    margin: 0 auto;
    overflow: scroll;
}

/* ------- Video & iFrames window ------ */
.venoframe{
    border: none;
    width: 960px;
    height: 720px;
    overflow: scroll;
}
@media (max-width: 992px) {
    .venoframe{
        width: 640px;
        height: 480px;
    }
}
@media (max-width: 767px) {
    .venoframe{
        width: 420px;
        height: 315px;
    }
}
@media (max-width: 460px) {
    .vbox-inline{
        width: 100%;
    }

    .venoframe{
        width: 100%;
        height: 260px;
    }
}

/* ------- PLease do NOT edit this! (or do it at your own risk) ------ */
.vbox-container{
    position: relative;
    background: #000;
    width: 98%;
    max-width: 1024px;
    margin: 0 auto;
}
.vbox-content{
    text-align: center;
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.vbox-container img{
    /* max-width: 100%; */
    max-width: 600px;
    width: 100%;
    height: auto;
}
.vwrap{
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    width: 100%;
    float: left;
    position: relative;
    margin: 0;
    padding: 0;
    left: 0;
    height: auto;
    overflow: hidden;
    z-index: 1;
}
