.pro-item {
    position: relative;

    &:hover {
        .pro-item__caption {
            background-color: color(#231f20 a(.9));
        }

        .pro-item__title {
            left: 50%;
            bottom: 50%;
            font-size: 2rem;
            color: white;
            transform:  translate(-50%, 50%);
            text-align: center;
            @media (--ipad_mini) {
                font-size: 1.4rem;
            }
        }
    }
}

