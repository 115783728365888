.item__btn {
    overflow: hidden;
    align-self: flex-start;
    max-height: 0;
    padding: 0;
    opacity: 0;
    transition: max-height .4s linear,
                padding .4s linear,
                opacity .4s linear;
}
