.passenger-detail__table {
    @define-mixin bd {
        border: 2px solid var(--color__gray);
    }

    width: 100%;
    @mixin bd;
    text-align: left;

    thead {
        background-color: var(--color__gray);
    }

    th,
    td {
        padding: .4em 1em;
        @mixin bd;
        font: .9rem regular, serif;
        color: var(--color__text);
    }
}
