.item {
    display: block;
    position: relative;
    overflow: hidden;

    &:hover {
        .item {
            &__text {
                max-height: 5em;
                opacity: 1;
            }

            &__btn {
                max-height: 5em;
                opacity: 1;
                padding: .3em 2em;
            }
        }
    }
}
