.menu__item {
    display: block;
    width: 100%;
    height: 2px;
    background-color: white;
    transition: width .4s;

    &:nth-child(even) {
        margin-left: auto;
    }

    & + & {
        margin-top: 5px;
    }
}
