.society {
    float: right;
    line-height: 1;
    @media (--phone){
        &.align-right {
            text-align: center;
        }
        &.align-left {
            text-align: center;
        }
    }
}
