html, body, 
h1, h2, h3, 
h4, h5, h6, 
p, 
dl, dt, dd, 
ol, ul, li, 
fieldset, 
form, label, input, 
legend, table, caption, 
tbody, tfoot, thead, 
tr, th, td, 
textarea, article, 
aside, audio, video, 
canvas, figure, 
footer, header, 
mark, menu, nav, 
section, time { 
    margin: 0; 
    padding: 0; 
}
h1, h2, h3, 
h4, h5, h6 { 
    font-size: 100%; 
    font-weight: normal;
}
article, aside, dialog, 
figure, footer, header, 
hgroup, nav, section, 
blockquote { 
    display: block; 
}
ul, ol { 
    list-style: none; 
}
img { 
    max-width: 100%;
    border: 0 none;
    vertical-align: top; 
}
blockquote, q { 
    quotes: none; 
}
blockquote:before, 
blockquote:after, 
q:before, q:after { 
    content: none; 
}
table { 
    border-collapse: collapse; 
    border-spacing: 0; 
}
strong, em, i { 
    font-style: normal; 
    font-weight: normal; 
}
ins { 
    text-decoration: underline; 
}
del { 
    text-decoration: line-through; 
}
mark { 
    background: none; 
}
input::-ms-clear { 
    display: none !important; 
}
body, html {
    font-family: 'light', Arial, sans-serif, serif;
    font-size: responsive 14px 20px;
    font-range: 420px 1280px;
}
a { 
    text-decoration: none; 
    color: #333; 

    &:hover {
        text-decoration: none;
    }
}
button,
input,
textarea {
    &:focus {
        outline: 0;
    }
}