.pro-item__title {
    position: absolute;
    left: .3em;
    bottom: .3em;
    width: 6em;
    font: 3.5rem/.9 bold, serif;
    color: color(black a(.6));
    transition: color .4s,
                font-size .4s,
                transform .2s .2s,
                left .6s,
                bottom .6s;
    @media (--ipad) {
        font-size: 2rem;
    }
    @media (--ipad_mini) {
        font-size: 2rem;
    }
    @media (--phone) {
        font-size: 2rem;
    }
}
