.society__item {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    font-size: 0;
    vertical-align: middle;
    transition: transform .5s;

    &:hover {
        transform:  rotateY(360deg);
    }
}
@each $i in facebook, instagram, twitter {
    .society__item_$(i) {
        background: svg-inline($(i)_w) center/contain no-repeat;
    }
}
@each $i in facebook, instagram, twitter {
    .society__item_$(i)_p{
        background: svg-inline($(i)_p) center/contain no-repeat;
    }
}
