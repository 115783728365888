.passenger-item__img {
    overflow: hidden;
    position: relative;
    border: 1px solid var(--color__gray);
    border-bottom: none;
    transition: filter .3s linear;

    &:after {
        content: 'read more';
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: color(black a(.5));
        color: white;
        text-transform: uppercase;
        font: 1rem regular, serif;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        opacity: 0;
        transform:  translateY(-80%);
        transition: transform .5s linear, 
                    opacity .4s linear;
    }
}
