.enquiry__btn {
    display: inline-block;
    padding: .2em 1em;
    margin: 0 .4em;
    background-color: var(--color__primary);
    border: 1px solid var(--color__primary);
    border-radius: .4em;
    text-transform: uppercase;
    font: .7em regular, serif;
    color: white;
}
