.service-item__title {
    position: absolute;
    left: 0;
    bottom: 2em;
    z-index: 2;
    width: 100%;
    padding: 0 1em;
    font: 1.6rem bold, serif;
    color: white;
}
