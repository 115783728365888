.address {
    display: flex;
    width: 100%;
    padding: 2em 0;
    background-color: var(--color__primary);
    flex-flow: column wrap;
    align-content: center;

    @media (--phone){
        > a {
            width: 60%;
            margin: 0 auto;
        }
    }
}
