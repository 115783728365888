.btn {
    display: inline-block;
    position: relative;
    overflow: hidden;
    padding: .3em 2em;
    border: 1px solid;
    border-radius: 4em;
    text-transform: uppercase;
    font: 12px regular, serif;
    transition: color .3s;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: calc(100% + 1px);
        transform-origin: right;
        transform:  scale(0, 1);
        transition: transform .6s;
    }

    &:hover {
        color: white;

        &:after {
            transform-origin: left;
            transform: scale(1); 
        }
    }
}
